<app-bar-top></app-bar-top>
<app-bar-icons></app-bar-icons>

<div id="profile" class="container d-flex flex-column flex-grow-1 mt-5 pt-4 pb-4 h-100" role="main" [attr.aria-label]="('PROFILE' | translate)">
  <app-headline [type]="'h4'" [class]="'text-muted fw-bold mb-2'" [title]="('PROFILE' | translate)"></app-headline>
  <div class="custom-scrollbar overflow-y-auto mb-2">
    <div class="profileCard card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-end mb-3">
          <div class="my-1 d-none d-md-flex">
            <app-form-button [text]="'SAVECHANGES' | translate" (click)="save()" [icon]="'save'" [iconPosition]="'right'" [cssClass]="'e-primary e-small fs-default fw-semibold'" [iconClass]="'fs-5'"></app-form-button>
          </div>
          <app-form-button [isFab]="true" [fabPosition]="'bottom-right'" [fabTargetContainer]="'#profile'" [text]="'SAVECHANGES' | translate" (click)="save()" [icon]="'save'" [iconPosition]="'right'" [cssClass]="'d-flex d-md-none e-primary fw-semibold'" [iconClass]="'fs-5'"></app-form-button>
        </div>
        <div class="row g-3">
          <!-- Profile Image Section -->
          <div class="col-md-4 my-3 text-center d-flex flex-column">
            <div class="mb-3">
              <app-headline [type]="'h5'" [class]="'text-muted fs-default fw-bold'" [title]="('PROFILEIMAGE' | translate)"></app-headline>
            </div>
            <div class="d-flex flex-grow-1 flex-column align-items-center justify-content-center">
              <img *ngIf="profileForm.controls['avatar'].value" [ngSrc]="profileForm.controls['avatar'].value" [width]="220" [height]="220" [alt]="('PROFILEIMAGE' | translate)" class="img-thumbnail" priority />
              <div class="d-flex align-items-center my-2">
                <app-form-button [text]="'CHANGEPROFILEIMAGE' | translate" (click)="documentsUploadDialog = true;" [cssClass]="'e-flat e-small fs-default fw-bold text-skyblue'" [icon]="'photo_camera'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
              </div>
            </div>
            <div class="align-items-center">
              <app-form-button [text]="'CHANGECREDENTIALS' | translate" (click)="profileCredentialsDialog = true" [cssClass]="'e-skyblue e-small mt-1 fs-default fw-semibold '"></app-form-button>
            </div>
          </div>

          <!-- Employee Details Section -->
          <div class="col-md-8">
            <div class="mb-3">
              <app-headline [type]="'h5'" [class]="'text-muted fs-default fw-bold'" [title]="('EMPLOYEEDETAILS' | translate)"></app-headline>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <app-form-label [label]="'COMPANYNAME' | translate" [maxChars]="255" [currentChars]="profileForm.controls['company'].value?.length"></app-form-label>
                <app-form-input [placeholder]="('COMPANYNAME' | translate)" [errorText]="('COMPANYNAMEREQUIRED' | translate)" [fCN]="'company'" [fG]="profileForm" [maxChars]="255"></app-form-input>
              </div>
              <div class="col-md-6">
                <app-form-label [label]="'SALUTATION' | translate" [maxChars]="45" [currentChars]="profileForm.controls['title'].value?.length"></app-form-label>
                <app-form-input [placeholder]="('SALUTATION' | translate)" [errorText]="('SALUTATIONREQUIRED' | translate)" [fCN]="'title'" [fG]="profileForm" [maxChars]="45"></app-form-input>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <app-form-label [label]="'FIRSTNAME' | translate" [maxChars]="255" [currentChars]="profileForm.controls['first_name'].value?.length"></app-form-label>
                <app-form-input [placeholder]="('FIRSTNAME' | translate)" [errorText]="('FIRSTNAMEREQUIRED' | translate)" [fCN]="'first_name'" [fG]="profileForm" [maxChars]="255"></app-form-input>
              </div>
              <div class="col-md-6">
                <app-form-label [label]="'LASTNAME' | translate" [maxChars]="255" [currentChars]="profileForm.controls['last_name'].value?.length"></app-form-label>
                <app-form-input [placeholder]="('LASTNAME' | translate)" [errorText]="('LASTNAMEREQUIRED' | translate)" [fCN]="'last_name'" [fG]="profileForm" [maxChars]="255"></app-form-input>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-6">
                <app-form-label [label]="'MOBILE' | translate"></app-form-label>
                <app-form-input [placeholder]="('MOBILE' | translate)" [errorText]="('MOBILEREQUIRED' | translate)" [iconBack]="'content_copy'" [iconClickType]="'back'" [fCN]="'mobile'" [fG]="profileForm" [maxChars]="255" (iconBackClick)="copy('mobile')"></app-form-input>
              </div>
              <div class="col-md-6">
                <app-form-label [label]="'EMAIL' | translate"></app-form-label>
                <app-form-input [placeholder]="('EMAIL' | translate)" [errorText]="('EMAILREQUIRED' | translate)" [iconBack]="'content_copy'" [iconClickType]="'back'" [fCN]="'email'" [fG]="profileForm" [maxChars]="191" (iconBackClick)="copy('email')"></app-form-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <app-form-label [label]="'TELEPHONE' | translate"></app-form-label>
                <app-form-input [placeholder]="('TELEPHONE' | translate)" [errorText]="('TELEPHONEREQUIRED' | translate)" [iconBack]="'content_copy'" [fCN]="'telephone'" [iconClickType]="'back'" [fG]="profileForm" [maxChars]="255" (iconBackClick)="copy('telephone')"></app-form-input>
              </div>
              <div class="col-md-6 pt-2 d-flex align-items-center mt-md-0 mt-3">
                <app-form-button [text]="'CUSTOMINFORMATION' | translate" (click)="profileInformationDialog = true" [cssClass]="'e-grey e-small fs-default fw-semibold w-100'"></app-form-button>
              </div>
            </div>

          </div>
        </div>

        <hr />

        <!-- Global Settings Section -->
        <div class="row mb-3">
          <div class="col-12">
            <app-form-label [label]="'NOTIFICATIONSETTINGS' | translate"></app-form-label>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-12 d-flex align-items-center">
              <span class="me-auto pe-2 text-muted">
                <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEALL' | translate"></app-form-label>
              </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profileForm" [fCN]="'push_disable_all'"></app-form-switch>
              </div>
            </div>
          </div>
        </div>

        <!-- Custom Settings Section -->
        <div class="row align-items-center mb-3">
          <div class="col-12">
            <app-form-label [label]="'CUSTOMSETTINGS' | translate"></app-form-label>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <span class="me-auto pe-2 text-muted">
                <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEMOBILE' | translate"></app-form-label>
              </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profileForm" [fCN]="'push_disable_mobile'"></app-form-switch>
              </div>
            </div>
            <div class="col-12 col-md-6 d-flex align-items-center">
              <span class="me-auto pe-2 text-muted">
                <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEEMAIL' | translate"></app-form-label>
              </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profileForm" [fCN]="'push_disable_email'"></app-form-switch>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <span class="me-auto pe-2 text-muted">
                <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEWEBSITE' | translate"></app-form-label>
              </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profileForm" [fCN]="'push_disable_browser'"></app-form-switch>
              </div>
            </div>
            <div class="col-12 col-md-6 d-flex align-items-center">
              <span class="me-auto pe-2 text-muted">
                <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLECHAT' | translate"></app-form-label>
              </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profileForm" [fCN]="'push_disable_chat'"></app-form-switch>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="d-flex mb-2">
            <app-headline [type]="'h5'" [class]="'text-muted fs-default fw-bold'" [title]="('ASSIGNEDINGROUPS' | translate)"></app-headline>
          </div>
          <div class="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 justify-content-start g-2 p-sm-0">
            <ng-container *ngFor="let group of groups">
              <div class="d-flex">
                <div class="group-card flex-grow-1">
                  <app-avatar [image]="group.image" [name]="group.name!" [type]="'xxs'" [icon]="'group'" [border]="false"></app-avatar>
                  <app-headline [type]="'h6'" [title]="group.name!" [class]="'text-muted fs-small'"></app-headline>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-dialog-profile-credentials *ngIf="profileCredentialsDialog" [(visible)]="profileCredentialsDialog" [(fG)]="profileForm" (saveEvent)="save()"></app-dialog-profile-credentials>
<app-dialog-profile-information *ngIf="profileInformationDialog" [(visible)]="profileInformationDialog" [(fG)]="profileForm" (saveEvent)="save()"></app-dialog-profile-information>
<app-dialog-documents-upload *ngIf="documentsUploadDialog" [(visible)]="documentsUploadDialog" [title]="('MANAGEAVATARIMAGE' | translate)" [tooltip]="('MANAGECREDENTIALSTOOLTIP' | translate)" (documentsUploaded)="setAvatar($event)"></app-dialog-documents-upload>
