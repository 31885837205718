import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {LanguageService} from "../../shared/services/language.service";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {IconBadgeComponent} from "../../shared/elements/icon-badge/icon-badge.component";
import {BarTopComponent} from "../helper/bar-top/bar-top.component";
import {FormInputComponent} from "../../shared/elements/form-input/form-input.component";
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormButtonComponent} from "../../shared/elements/form-button/form-button.component";
import {DialogComponent, ResizeDirections} from '@syncfusion/ej2-angular-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import {BarIconsComponent} from "../helper/bar-icons/bar-icons.component";
import {FormDropdownComponent} from "../../shared/elements/form-dropdown/form-dropdown.component";
import {ToastComponent, ToastModule} from '@syncfusion/ej2-angular-notifications';
import {ToastUtility} from '@syncfusion/ej2-notifications';
import {LinkComponent} from "../../shared/elements/link/link.component";
import {Subscription} from "rxjs";
import {ApiService} from "../../shared/services/api.service";
import {TYPE} from "../../shared/types/types";
import {LayoutService} from "../../shared/services/layout.service";
import {AvatarComponent} from "../../shared/elements/avatar/avatar.component";
import {ImagePreviewComponent} from "../../shared/elements/image-preview/image-preview.component";
import {UserService} from "../../shared/services/user.service";
import {TooltipModule} from "@syncfusion/ej2-angular-popups";
import {TooltipComponent} from "../../shared/elements/tooltip/tooltip.component";
import {DialogService} from "../../shared/services/dialog.service";
import {StepHeaderComponent} from "../../shared/elements/step-header/step-header.component";
import {StepContentComponent} from "../../shared/elements/step-content/step-content.component";
import {FormLabelComponent} from "../../shared/elements/form-label/form-label.component";
import {FormTextareaComponent} from "../../shared/elements/form-textarea/form-textarea.component";
import {FormUserGroupMailComponent} from "../../shared/elements/form-user-group-mail/form-user-group-mail.component";
import {FormCheckboxComponent} from "../../shared/elements/form-checkbox/form-checkbox.component";
import {FormDateTimeComponent} from "../../shared/elements/form-date-time/form-date-time.component";
import {ToasterService} from "../../shared/services/toaster.service";
import { DialogUtility } from '@syncfusion/ej2-popups';

@Component({
  selector: 'app-feedback',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    BarTopComponent,
    TranslatePipe,
    HeadlineComponent,
    FormInputComponent,
    FormButtonComponent,
    BarIconsComponent,
    FormDropdownComponent,
    ToastModule,
    IconBadgeComponent,
    LinkComponent,
    AvatarComponent,
    ImagePreviewComponent,
    TooltipModule,
    TooltipComponent,
    StepHeaderComponent,
    StepContentComponent,
    FormLabelComponent,
    FormTextareaComponent,
    FormUserGroupMailComponent,
    FormCheckboxComponent,
    FormDateTimeComponent
  ],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss'
})
export class FeedbackComponent  implements OnInit, OnDestroy{

  constructor(
    public layoutService: LayoutService,
    public userService: UserService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private apiService: ApiService,
    public dialogService: DialogService,
    private toasterService: ToasterService,
  ) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  /**** FORM GROUP */
  public feedbackForm: FormGroup = this.formBuilder.group({
    search_feedback_string: [],
  });
  /**** FORM GROUP END */

   /**** FAKE FUNCTION */
   fakeMethod = (): void => {
    console.log("Fake method");
  }

  /***DIALOGS */
  public visible=false;
  public visible1=false;
  public activeStep: number = 0;
  onOpenDialog = (event: any, id: string): void => {
    event.preventDefault();
    if (id == 'create-newpost') {
      this.activeStep=0;
      this.visible=true;
    }
    if (id == 'share-post') {
      this.visible1=true;
    }
    
  }

  closeDialog(id: string = ""): void {
    
  }

  setStep(activeStep: number): void {
    this.activeStep = activeStep;
  }
  /***END */

  /***** ADMIN/USER MODE SWITCH */
  isAdmin=true;
  selectedCard: string = 'manage';
  onChangeMode()
  {
    this.isAdmin = !this.isAdmin;
    this.selectedCard = this.isAdmin ? 'manage' : 'mandatory';
  }
  /**** END */

  /**** ADMIN/USER MAIN MENU CONTENT */

  setSelectedCard(card: string) {
    this.selectedCard = card;
  }
  /**** END  */
}
