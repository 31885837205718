import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {RadioButtonModule} from "@syncfusion/ej2-angular-buttons";

@Component({
  selector: 'app-form-radio',
  standalone: true,
  imports: [
    DropDownListModule,
    ReactiveFormsModule,
    RadioButtonModule
  ],
  templateUrl: './form-radio.component.html',
  styleUrl: './form-radio.component.scss'
})
export class FormRadioComponent {

  @Input() fCN: string = '';
  @Input() value: string = '';
  @Input() disabled: boolean = false;
  @Input() cssClass: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() placeholder: string = '';
  @Output() valueChange = new EventEmitter<string>();

  public onChange(event: any): void {
    this.valueChange.emit(event.value);
  }
}
