import {Routes} from '@angular/router';
import {languageGuard} from "./shared/guards/language.guard";
import {customerGuard} from "./shared/guards/customer.guard";
import {userGuard} from "./shared/guards/user.guard";
import {TestComponent} from "./modules/test/test.component";
import {TestComponent1} from "./modules/test1/test1.component";
import {HomeComponent} from "./modules/home/home.component";
import {PageNotFoundComponent} from "./modules/helper/page-not-found/page-not-found.component";
import {ProfileComponent} from "./modules/profile/profile.component";
import {FeedbackComponent} from "./modules/feedback/feedback.component";

export const routes: Routes = [
  // redirects
  {path: '', redirectTo: '/auth/customer', pathMatch: 'full'},
  {path: 'customer/:customerKey', redirectTo: '/auth/customer/:customerKey', pathMatch: 'full'},

  // identical to app
  {path: "auth", loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)},
  {path: "home", component: HomeComponent, title: 'HOME', canActivate: [languageGuard, customerGuard, userGuard]},
  {path: "news", loadChildren: () => import('./modules/news/news.module').then((m) => m.NewsModule)},
  {path: "calendar", loadChildren: () => import('./modules/calendar/calendar.module').then((m) => m.CalendarModule)},
  {path: "feedback", component: FeedbackComponent, title: 'FEEDBACK'},
  {path: "profile", component: ProfileComponent, title: 'PROFILE', canActivate: [languageGuard, customerGuard, userGuard]},

  // helper
  {path: 'test', component: TestComponent}, // TODO remove before live
  {path: 'test1', component: TestComponent1}, // TODO remove before live
  {path: '**', component: PageNotFoundComponent, title: 'PAGENOTFOUND', canActivate: [languageGuard]},
];
