import {HttpInterceptorFn} from '@angular/common/http';
import {catchError} from "rxjs";
import {inject} from "@angular/core";
import {ToasterService} from "../services/toaster.service";
import {UserService} from "../services/user.service";
import {RouterService} from "../services/router.service";

export const statusInterceptor: HttpInterceptorFn = (req, next) => {
  const toasterService = inject(ToasterService);
  const userService = inject(UserService);
  const routerService = inject(RouterService);

  return next(req).pipe(
    catchError(error => {
      if (error.status === 415 || error.status === 555) { // error codes used in the backend
        toasterService.error(error.error.title, error.error.message);
        // if you want to return a custom error
        /*return of(new HttpResponse({
          status: 200,
          body: {
            message: 'Fehler 555 wurde abgefangen',
            data: null
          }
        }));*/
      }

      if (error.status === 401 || error.status === 403 || error.status === 404 || error.status === 405 || error.status === 500) { // error codes used in the backend
        toasterService.error(error.error.title, error.error.message);
        userService.resetUser();
        routerService.navigateTo('/auth/login');
      }
      throw error;
    })
  );
};
