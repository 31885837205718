<ejs-dialog target=".dialogs" class="steps-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'60%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="false">
  <ng-template #header class="p-1">
    <app-step-header [steps]="steps" [(activeStep)]="activeStep"></app-step-header>
  </ng-template>

  <ng-template #content>
    <div class="d-flex h-100">
      <!-- Left Column -->
      <div class="col-md-4 col-12 d-none d-md-flex flex-column left-container h-100 p-3">
        <div class="text-center mb-4">
          <app-headline [title]="'HOMEADDLINK' | translate" [type]="'h5'"></app-headline>
        </div>
        <app-step-content [steps]="steps" [(activeStep)]="activeStep"></app-step-content>
        <div class="save-button-container text-center mt-3 d-none d-md-block">
          <app-form-button [text]="'SAVE' | translate" [disabled]="homeLinkForm.invalid" (click)="save()" [cssClass]="'e-primary w-100'"></app-form-button>
        </div>
      </div>

      <!-- Right Column -->
      <div class="col-md-8 col-12 d-flex flex-column flex-grow-1 right-container h-100 overflow-y-auto p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="d-flex align-items-center">
            <app-headline [title]="steps[activeStep]" [type]="'h5'"></app-headline>
            <div class="ms-2">
              <app-tooltip [icon]="'help'" [iconClass]="'fs-5 p-1'" [text]="('HOMELINKTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
            </div>
          </div>
          <app-form-button [style]="'btn'" (click)="close()" [cssClass]="'btn-close'"></app-form-button>
        </div>

        <!-- Scrollable Form Container -->
        <div class="custom-scrollbar overflow-y-auto">
          <form class="p-1 d-flex flex-column h-100">
            <ng-container [ngSwitch]="activeStep">
              <ng-container *ngSwitchCase="0">
                <app-form-label [label]="'HOMELINKNAME' | translate" [maxChars]="35" [currentChars]="homeLinkForm.controls['name'].value.length"></app-form-label>
                <div class="mb-3">
                  <app-form-input [placeholder]="('HOMELINKNAME' | translate)" [label]="('HOMELINKNAME' | translate)" [errorText]="('HOMELINKNAMEREQUIRED' | translate)" [fCN]="'name'" [fG]="homeLinkForm" [maxChars]="35"></app-form-input>
                </div>
                <app-form-label [label]="'HOMELINKURL' | translate" [maxChars]="255" [currentChars]="homeLinkForm.controls['name'].value.length"></app-form-label>
                <div class="mb-3">
                  <app-form-input [placeholder]="('HOMELINKURL' | translate)" [label]="('HOMELINKURL' | translate)" [errorText]="('HOMELINKURLREQUIRED' | translate)" [fCN]="'url'" [fG]="homeLinkForm" [maxChars]="255"></app-form-input>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <app-form-label [label]="'HOMELINKTYPE' | translate"></app-form-label>
                <div class="d-flex align-items-center mb-4">
                  <div class="col-4 col-md-3 me-2">
                    <app-form-radio [fG]="homeLinkForm" [fCN]="'type'" [value]="'internal'" [placeholder]="'HOMELINKTYPEINTERNAL' | translate"></app-form-radio>
                  </div>
                  <div class="col-4 col-md-3 me-2">
                    <app-form-radio [fG]="homeLinkForm" [fCN]="'type'" [value]="'external'" [placeholder]="'HOMELINKTYPEEXTERNAL' | translate"></app-form-radio>
                  </div>
                </div>
                <app-form-label [label]="'HOMELINKPLATFORM' | translate"></app-form-label>
                <div class="d-flex align-items-center mb-4">
                  <div class="col-4 col-md-3 me-2">
                    <app-form-radio [fG]="homeLinkForm" [fCN]="'platform'" [value]="'both'" [placeholder]="'HOMELINKPLATFORMBOTH' | translate"></app-form-radio>
                  </div>
                  <div class="col-4 col-md-3 me-2">
                    <app-form-radio [fG]="homeLinkForm" [fCN]="'platform'" [value]="'mobile'" [placeholder]="'HOMELINKPLATFORMMOBILE' | translate"></app-form-radio>
                  </div>
                  <div class="col-4 col-md-3 me-2">
                    <app-form-radio [fG]="homeLinkForm" [fCN]="'platform'" [value]="'desktop'" [placeholder]="'HOMELINKPLATFORMDESKTOP' | translate"></app-form-radio>
                  </div>
                </div>
                <app-form-label [label]="'HOMELINKAVAILABLE' | translate"></app-form-label>
                <div class="row g-2 justify-content-start">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center">
                        <span class="text-muted fs-default">
                          <app-form-label [type]="'content'" [label]="'HOMELINKDATETIMESTART' | translate"></app-form-label>
                        </span>
                      </div>
                      <div class="col-9 col-sm-9 col-md-6 col-lg-6">
                        <app-form-date-time [fG]="homeLinkForm" [fCN]="'dateTimeStart'" [placeholder]="'SELECTDATE' | translate"></app-form-date-time>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center">
                        <span class="text-muted fs-default">
                          <app-form-label [type]="'content'" [label]="'HOMELINKDATETIMEEND' | translate"></app-form-label>
                        </span>
                      </div>
                      <div class="col-9 col-sm-9 col-md-6 col-lg-6">
                        <app-form-date-time [fG]="homeLinkForm" [fCN]="'dateTimeEnd'" [placeholder]="'SELECTDATE' | translate"></app-form-date-time>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <app-form-user-group-mail [(user)]="participantsUserIDs" [(groups)]="participantsGroupIDs"></app-form-user-group-mail>
              </ng-container>
            </ng-container>
          </form>
        </div>

        <div class="navigation-buttons d-flex justify-content-between mt-3 d-none d-md-flex">
          <app-form-button [text]="'PREV' | translate" *ngIf="activeStep > 0" (click)="activeStep = activeStep - 1" [cssClass]="'e-outline w-10'"></app-form-button>
          <app-form-button [text]="'NEXT' | translate" *ngIf="activeStep < steps.length - 1" (click)="activeStep = activeStep + 1" [cssClass]="'e-secondary ms-auto w-10'"></app-form-button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="mt-auto d-md-none col-12 d-flex justify-content-between navigation-buttons-mobile p-2">
      <app-form-button [text]="'PREV' | translate" *ngIf="activeStep > 0" (click)="activeStep = activeStep - 1" [cssClass]="'e-outline w-10 mx-1'"></app-form-button>

      <!-- Dummy button to keep the space for the flex container -->
      <app-form-button *ngIf="activeStep === 0" [text]="'PREV' | translate" [cssClass]="'e-outline invisible w-10 mx-1'"></app-form-button>

      <app-form-button [text]="'SAVE' | translate" [disabled]="homeLinkForm.invalid" (click)="save()" [cssClass]="'e-primary mx-auto w-100'"></app-form-button>

      <app-form-button [text]="'NEXT' | translate" *ngIf="activeStep < steps.length - 1" (click)="activeStep = activeStep + 1" [cssClass]="'e-secondary w-10 mx-1'"></app-form-button>

      <!-- Dummy button to keep the space for the flex container -->
      <app-form-button [text]="'NEXT' | translate" *ngIf="activeStep === steps.length - 1" [cssClass]="'e-secondary invisible w-10 mx-1'"></app-form-button>
    </div>
  </ng-template>
</ejs-dialog>
