<app-bar-top></app-bar-top>
<app-bar-icons></app-bar-icons>

<div id="app-feedback" class="container d-flex flex-column flex-grow-1 mt-5 pt-4 pb-4 h-100" role="main" [attr.aria-label]="('FEEDBACK' | translate)">
    <div class="d-flex justify-content-between align-items-center">
      <app-headline [type]="'h4'" [class]="'text-muted fw-bold m-0'" [title]="('FEEDBACK' | translate)"></app-headline>
      <div class="d-flex align-items-center">
        <!-- Status Text for Admin/User -->
        <app-headline [type]="'h6'" [class]="'text-muted fw-bold m-0'" [title]="(isAdmin ? 'FEEDBACKADMINMODESTATUSTEXT' : 'FEEDBACKUSERMODESTATUSTEXT') | translate"></app-headline>
      
        <!-- Admin Mode Button -->
        <ejs-tooltip target=".adminmode-target" [content]="'FEEDBACKADMINMODETOOLTIP' | translate" opensOn="Auto" position="TopCenter" [animation]="'Fade'" [windowCollision]="'flip'">
          <app-form-button (click)="onChangeMode()" [cssClass]="isAdmin ? 'e-btn e-primary m-1 px-1 adminmode-target' : 'e-btn e-white m-1 px-1 adminmode-target'" [icon]="'admin_panel_settings'" [iconPosition]="'right'" [iconClass]="'fs-3'"></app-form-button>
        </ejs-tooltip>
      
        <!-- User Mode Button -->
        <ejs-tooltip target=".usermode-target" [content]="'FEEDBACKUSERMODETOOLTIP' | translate" opensOn="Auto" position="TopCenter" [animation]="'Fade'" [windowCollision]="'flip'">         
          <app-form-button (click)="onChangeMode()" [cssClass]="isAdmin ? 'e-btn e-white m-1 px-1 usermode-target' : 'e-btn e-primary m-1 px-1 usermode-target'" [icon]="'person'" [iconPosition]="'right'" [iconClass]="'fs-3'"></app-form-button>
        </ejs-tooltip>
      </div>
    </div>
    <div class="custom-scrollbar overflow-y-auto mb-3">
      <div class="card mb-3">
        <div class="card-body p-0">
          <div class="card border-0 box-shadow-bottom">
            <div class="card-body text-center p-4">
              <div class="my-3">
                <div class="no-content-large-icon bg-lightpurple rounded-circle d-flex justify-content-center align-items-center mx-auto">
                  <span class="mts-icon fs-3 text-white">cycle</span>
                </div>
                <h5 class="card-title mt-3">You don’t have any Feedbacks yet</h5>
              </div>
              
              <div *ngIf="isAdmin" class="d-flex justify-content-center align-items-center mt-5">
                <div class="row g-3 row-cols-1 row-cols-md-3 w-100">
                  <div class="col d-flex">
                    <div class="card card-selectable h-100 d-flex flex-column flex-grow-1 cursor-pointer"
                        [class.active]="selectedCard === 'manage'"
                        (click)="setSelectedCard('manage')">
                      <div class="card-body d-flex flex-column align-items-start flex-grow-1 justify-content-between">
                        <div class="d-flex align-items-center gap-2 mb-2">
                          <div class="icon d-flex align-items-center justify-content-center">
                            <span class="mts-icon fs-3 text-muted">cycle</span>
                          </div>
                          <h5 class="card-title m-0">Manage all</h5>
                        </div>
                        <p class="card-text text-secondary">Create or manage all existing feedbacks</p>
                      </div>
                    </div>
                  </div>
              
                  <div class="col d-flex">
                    <div class="card card-selectable h-100 d-flex flex-column flex-grow-1 cursor-pointer" 
                        [class.active]="selectedCard === 'drafts'"
                        (click)="setSelectedCard('drafts')">
                      <div class="card-body d-flex flex-column align-items-start flex-grow-1 justify-content-between">
                        <div class="d-flex align-items-center gap-2 mb-2">
                          <div class="icon d-flex align-items-center justify-content-center">
                            <span class="mts-icon fs-3 text-muted">draft_orders</span>
                          </div>
                          <h5 class="card-title m-0">Drafts</h5>
                        </div>
                        <p class="card-text text-secondary">List of shared feedbacks with Draft Members</p>
                      </div>
                    </div>
                  </div>
              
                  <div class="col d-flex">
                    <div class="card card-selectable h-100 d-flex flex-column flex-grow-1 cursor-pointer" 
                        [class.active]="selectedCard === 'completed'"
                        (click)="setSelectedCard('completed')">
                      <div class="card-body d-flex flex-column align-items-start flex-grow-1 justify-content-between">
                        <div class="d-flex align-items-center gap-2 mb-2">
                          <div class="icon d-flex align-items-center justify-content-center">
                            <span class="mts-icon fs-3 text-muted">fact_check</span>
                          </div>
                          <h5 class="card-title m-0">Completed</h5>
                        </div>
                        <p class="card-text text-secondary">List of all feedbacks completed by Users</p>
                      </div>
                    </div>
                  </div>
              
                </div>
              </div>
              <div *ngIf="!isAdmin" class="d-flex justify-content-center align-items-center mt-5">
                <div class="row g-3 row-cols-1 row-cols-md-3 w-100">
                  <div class="col d-flex">
                    <div class="card card-selectable h-100 d-flex flex-column flex-grow-1 cursor-pointer"
                        [class.active]="selectedCard === 'mandatory'"
                        (click)="setSelectedCard('mandatory')">
                      <div class="card-body d-flex flex-column align-items-start flex-grow-1 justify-content-between">
                        <div class="d-flex align-items-center gap-2 mb-2">
                          <div class="icon d-flex align-items-center justify-content-center">
                            <span class="mts-icon fs-3 text-muted">release_alert</span>
                          </div>
                          <h5 class="card-title m-0">Mandatory</h5>
                        </div>
                        <p class="card-text text-secondary">Feedbacks you must complete to stay compliant.</p>
                      </div>
                    </div>
                  </div>
              
                  <div class="col d-flex">
                    <div class="card card-selectable h-100 d-flex flex-column flex-grow-1 cursor-pointer" 
                        [class.active]="selectedCard === 'optional'"
                        (click)="setSelectedCard('optional')">
                      <div class="card-body d-flex flex-column align-items-start flex-grow-1 justify-content-between">
                        <div class="d-flex align-items-center gap-2 mb-2">
                          <div class="icon d-flex align-items-center justify-content-center">
                            <span class="mts-icon fs-3 text-muted">alt_route</span>
                          </div>
                          <h5 class="card-title m-0">Optional</h5>
                        </div>
                        <p class="card-text text-secondary">Feedback you can choose to take part in.</p>
                      </div>
                    </div>
                  </div>
              
                  <div class="col d-flex">
                    <div class="card card-selectable h-100 d-flex flex-column flex-grow-1 cursor-pointer" 
                        [class.active]="selectedCard === 'completed_user'"
                        (click)="setSelectedCard('completed_user')">
                      <div class="card-body d-flex flex-column align-items-start flex-grow-1 justify-content-between">
                        <div class="d-flex align-items-center gap-2 mb-2">
                          <div class="icon d-flex align-items-center justify-content-center">
                            <span class="mts-icon fs-3 text-muted">fact_check</span>
                          </div>
                          <h5 class="card-title m-0">Completed</h5>
                        </div>
                        <p class="card-text text-secondary">Feedback you’ve already completed.</p>
                      </div>
                    </div>
                  </div>
              
                </div>
              </div>
              <div *ngIf="isAdmin && selectedCard === 'manage'" class="fade-card d-flex align-items-center gap-2 p-2 mt-5 rounded-3 shadow-sm bg-light border">
                <span class="mts-icon fs-5 text-lightpurple p-1">cycle</span>
                <div class="d-flex flex-column text-start flex-grow-1">
                  <strong>Create Feedback</strong>
                  <span class="text-muted small">Start your first Feedback now.</span>
                </div>
                <app-form-button [text]="'START' | translate" (click)="onOpenDialog($event, 'create-newpost')" [cssClass]="'e-primary fw-semibold'"></app-form-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-body p-card">
          
            <app-headline [title]="'TITLE OF THE SELECTED CARD'" [type]="'h5'" [class]="'text-primary mb-2'"></app-headline>
            <div class="d-flex align-items-center justify-content-end">
              
              <div class="col-12 col-md-6 col-lg-4">
                <app-form-input type="search" [hasErrorField]="false" [placeholder]="('SEARCHINFEEDBACKS' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'search_feedback_string'" [fG]="feedbackForm"></app-form-input>
              </div>
            </div>

        </div>
      </div>
    </div>
</div>