<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('CALPARTICIPANTS' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <app-tooltip [icon]="'help'" [iconClass]="'fs-5 p-1'" [text]="('CALPARTICIPANTSTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="d-flex flex-column h-100 p-3">
      <app-form-input type="search" [placeholder]="('SEARCHFORPARTICIPANT' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'searchString'" [fG]="calendarParticipantsForm"></app-form-input>
      <app-headline [title]="('CALNOPARTICIPANTSFOUND' | translate)" [type]="'h6'" [class]="'text-center text-secondary mb-3'"></app-headline>

      <!-- Scrollable content-->
      <div class="custom-scrollbar overflow-y-auto h-100">
        <app-form-user-group-mail [readonly]="true" [allowEmails]="true" [(user)]="calenderEvent.participantsUserIDs" [(groups)]="calenderEvent.participantsGroupIDs"></app-form-user-group-mail>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
