import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from "../../shared/services/loading.service";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {BarTopComponent} from "../helper/bar-top/bar-top.component";
import {BarIconsComponent} from "../helper/bar-icons/bar-icons.component";
import {FormButtonComponent} from "../../shared/elements/form-button/form-button.component";
import {FormInputComponent} from "../../shared/elements/form-input/form-input.component";
import {FormLabelComponent} from "../../shared/elements/form-label/form-label.component";
import {FormBuilder, FormGroup, FormsModule, Validators} from "@angular/forms";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {SwitchModule} from "@syncfusion/ej2-angular-buttons";
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {DialogService} from "../../shared/services/dialog.service";
import {Subscription} from "rxjs";
import {ApiService} from "../../shared/services/api.service";
import {FormSwitchComponent} from "../../shared/elements/form-switch/form-switch.component";
import {TYPE} from "../../shared/types/types";
import {IconBadgeComponent} from "../../shared/elements/icon-badge/icon-badge.component";
import {DialogProfileCredentialsComponent} from "./dialog-profile-credentials/dialog-profile-credentials.component";
import {DialogProfileInformationComponent} from "./dialog-profile-information/dialog-profile-information.component";
import {ToasterService} from "../../shared/services/toaster.service";
import {LanguageService} from "../../shared/services/language.service";
import {DialogDocumentsUploadComponent} from "../documents/dialog-documents-upload/dialog-documents-upload.component";
import {AvatarComponent} from "../../shared/elements/avatar/avatar.component";
import {DialogHomeLinkManagerComponent} from "../home/dialog-home-link-manager/dialog-home-link-manager.component";

@Component({
  selector: 'app-profile',
  standalone: true,
    imports: [
        NgIf,
        BarTopComponent,
        BarIconsComponent,
        FormButtonComponent,
        FormInputComponent,
        FormLabelComponent,
        FormsModule,
        HeadlineComponent,
        SwitchModule,
        TranslatePipe,
        NgOptimizedImage,
        FormSwitchComponent,
        NgForOf,
        IconBadgeComponent,
        DialogProfileCredentialsComponent,
        DialogProfileInformationComponent,
        DialogDocumentsUploadComponent,
        AvatarComponent,
        DialogHomeLinkManagerComponent
    ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit, OnDestroy {

  public profileForm: FormGroup = this.formBuilder.group({
    activated: [''],
    avatar: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    company: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    email: ['', [Validators.minLength(1), Validators.maxLength(191)]],
    first_name: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    id: ['', [Validators.required, Validators.min(1)]],
    info_01: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_02: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_03: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_04: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_05: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_06: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_07: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_08: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_09: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_10: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    info_11: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    is_mail_notify: [''],
    is_trans: [''],
    last_name: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    locale: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]],
    mobile: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    password: ['', [Validators.minLength(1), Validators.maxLength(191)]],
    telephone: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    title: ['', [Validators.minLength(1), Validators.maxLength(45)]],
    user_name: ['', [Validators.minLength(1), Validators.maxLength(255)]],
    push_disable_all: [''],
    push_disable_mobile: [''],
    push_disable_email: [''],
    push_disable_browser: [''],
    push_disable_chat: [''],
  });
  public groups: TYPE.GroupListItem[] = [];
  public profileCredentialsDialog: boolean = false;
  public profileInformationDialog: boolean = false;
  public documentsUploadDialog: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public loadingService: LoadingService,
    public dialogService: DialogService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToasterService,
    private languageService: LanguageService
  ) {
  }

  public ngOnInit(): void {
    this.load();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private load(): void {
    this.subscriptions.push(this.apiService.getProfile().subscribe((profile: any) => {
      this.profileForm.patchValue(profile);
      // TODO telephone value is sometimes NULL, which throws an error in the console, check also other values
      this.groups = profile.groups;
    }));
  }

  public save(): void {
    this.subscriptions.push(this.apiService.setProfile(this.profileForm.value).subscribe(() => {
      this.profileForm.controls['password'].setValue('');
      this.toasterService.success(this.languageService.getTranslationByKey('PROFILE'), this.languageService.getTranslationByKey('PROFILESAVED'));
    }));
  }

  public copy(formControlName: string): void {
    if (this.profileForm.controls[formControlName].value) {
      navigator.clipboard.writeText(this.profileForm.controls[formControlName].value).then(
        () => {
          this.toasterService.success(this.languageService.getTranslationByKey('PROFILE'), this.languageService.getTranslationByKey('COPIED'));
        },
        () => {
          this.toasterService.error(this.languageService.getTranslationByKey('PROFILE'), this.languageService.getTranslationByKey('COPYFAILED'));
        }
      );
    }
  }

  public setAvatar(documentsUploaded: TYPE.Document[]): void {
    const avatar = documentsUploaded[0].path;
    this.profileForm.controls['avatar'].setValue(avatar);
    this.save();
  }
}
