import {Inject, Injectable} from '@angular/core';
import {DOCUMENT, registerLocaleData} from "@angular/common";

// Deepl Languages
import localeDe from '@angular/common/locales/de';
import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeEn from '@angular/common/locales/en';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeEl from '@angular/common/locales/el';
import localeEs from '@angular/common/locales/es';
import localeEt from '@angular/common/locales/et';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeLv from '@angular/common/locales/lv';
import localeLt from '@angular/common/locales/lt';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeSl from '@angular/common/locales/sl';
import localeSv from '@angular/common/locales/sv';
import localeZh from '@angular/common/locales/zh';

// Google Languages
import localeAf from '@angular/common/locales/af';
import localeSq from '@angular/common/locales/sq';
import localeAm from '@angular/common/locales/am';
import localeAr from '@angular/common/locales/ar';
import localeHy from '@angular/common/locales/hy';
import localeAz from '@angular/common/locales/az';
import localeEu from '@angular/common/locales/eu';
import localeBe from '@angular/common/locales/be';
import localeBn from '@angular/common/locales/bn';
import localeBs from '@angular/common/locales/bs';
import localeCa from '@angular/common/locales/ca';
import localeHr from '@angular/common/locales/hr';
import localeEo from '@angular/common/locales/eo';
import localeFy from '@angular/common/locales/fy';
import localeGl from '@angular/common/locales/gl';
import localeKa from '@angular/common/locales/ka';
import localeGu from '@angular/common/locales/gu';
import localeHaw from '@angular/common/locales/haw';
import localeHi from '@angular/common/locales/hi';
import localeIs from '@angular/common/locales/is';
import localeIg from '@angular/common/locales/ig';
import localeId from '@angular/common/locales/id';
import localeGa from '@angular/common/locales/ga';
import localeKn from '@angular/common/locales/kn';
import localeKk from '@angular/common/locales/kk';
import localeKm from '@angular/common/locales/km';
import localeRw from '@angular/common/locales/rw';
import localeKo from '@angular/common/locales/ko';
import localeKy from '@angular/common/locales/ky';
import localeLo from '@angular/common/locales/lo';
import localeLb from '@angular/common/locales/lb';
import localeMk from '@angular/common/locales/mk';
import localeMg from '@angular/common/locales/mg';
import localeMs from '@angular/common/locales/ms';
import localeMl from '@angular/common/locales/ml';
import localeMt from '@angular/common/locales/mt';
import localeMr from '@angular/common/locales/mr';
import localeMn from '@angular/common/locales/mn';
import localeMy from '@angular/common/locales/my';
import localeNe from '@angular/common/locales/ne';
import localeOr from '@angular/common/locales/or';
import localePs from '@angular/common/locales/ps';
import localeFa from '@angular/common/locales/fa';
import localePa from '@angular/common/locales/pa';
import localeGd from '@angular/common/locales/gd';
import localeSr from '@angular/common/locales/sr';
import localeSn from '@angular/common/locales/sn';
import localeSd from '@angular/common/locales/sd';
import localeSi from '@angular/common/locales/si';
import localeSo from '@angular/common/locales/so';
import localeSw from '@angular/common/locales/sw';
import localeTg from '@angular/common/locales/tg';
import localeTa from '@angular/common/locales/ta';
import localeTt from '@angular/common/locales/tt';
import localeTe from '@angular/common/locales/te';
import localeTh from '@angular/common/locales/th';
import localeTr from '@angular/common/locales/tr';
import localeTk from '@angular/common/locales/tk';
import localeUk from '@angular/common/locales/uk';
import localeUr from '@angular/common/locales/ur';
import localeUg from '@angular/common/locales/ug';
import localeUz from '@angular/common/locales/uz';
import localeVi from '@angular/common/locales/vi';
import localeCy from '@angular/common/locales/cy';
import localeYi from '@angular/common/locales/yi';
import localeYo from '@angular/common/locales/yo';
import localeZu from '@angular/common/locales/zu';
import localeHe from '@angular/common/locales/he';

// Syncfusion
import {Ajax, loadCldr, setCulture} from '@syncfusion/ej2-base';

// Deepl Languages
registerLocaleData(localeDe);
registerLocaleData(localeBg);
registerLocaleData(localeCs);
registerLocaleData(localeDa);
registerLocaleData(localeEn);
registerLocaleData(localeFi);
registerLocaleData(localeFr);
registerLocaleData(localeEl);
registerLocaleData(localeEs);
registerLocaleData(localeEt);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localeLv);
registerLocaleData(localeLt);
registerLocaleData(localeNl);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeRo);
registerLocaleData(localeRu);
registerLocaleData(localeSk);
registerLocaleData(localeSl);
registerLocaleData(localeSv);
registerLocaleData(localeZh);

// Google Languages
registerLocaleData(localeAf);
registerLocaleData(localeSq);
registerLocaleData(localeAm);
registerLocaleData(localeAr);
registerLocaleData(localeHy);
registerLocaleData(localeAz);
registerLocaleData(localeEu);
registerLocaleData(localeBe);
registerLocaleData(localeBn);
registerLocaleData(localeBs);
registerLocaleData(localeCa);
registerLocaleData(localeHr);
registerLocaleData(localeEo);
registerLocaleData(localeFy);
registerLocaleData(localeGl);
registerLocaleData(localeKa);
registerLocaleData(localeGu);
registerLocaleData(localeHaw);
registerLocaleData(localeHi);
registerLocaleData(localeIs);
registerLocaleData(localeIg);
registerLocaleData(localeId);
registerLocaleData(localeGa);
registerLocaleData(localeKn);
registerLocaleData(localeKk);
registerLocaleData(localeKm);
registerLocaleData(localeRw);
registerLocaleData(localeKo);
registerLocaleData(localeKy);
registerLocaleData(localeLo);
registerLocaleData(localeLb);
registerLocaleData(localeMk);
registerLocaleData(localeMg);
registerLocaleData(localeMs);
registerLocaleData(localeMl);
registerLocaleData(localeMt);
registerLocaleData(localeMr);
registerLocaleData(localeMn);
registerLocaleData(localeMy);
registerLocaleData(localeNe);
registerLocaleData(localeOr);
registerLocaleData(localePs);
registerLocaleData(localeFa);
registerLocaleData(localePa);
registerLocaleData(localeGd);
registerLocaleData(localeSr);
registerLocaleData(localeSn);
registerLocaleData(localeSd);
registerLocaleData(localeSi);
registerLocaleData(localeSo);
registerLocaleData(localeSw);
registerLocaleData(localeTg);
registerLocaleData(localeTa);
registerLocaleData(localeTt);
registerLocaleData(localeTe);
registerLocaleData(localeTh);
registerLocaleData(localeTr);
registerLocaleData(localeTk);
registerLocaleData(localeUk);
registerLocaleData(localeUr);
registerLocaleData(localeUg);
registerLocaleData(localeUz);
registerLocaleData(localeVi);
registerLocaleData(localeCy);
registerLocaleData(localeYi);
registerLocaleData(localeYo);
registerLocaleData(localeZu);
registerLocaleData(localeHe);

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private allowedLaravelDeeplLanguages: string[] = ['de', 'bg', 'cs', 'da', 'en', 'fi', 'fr', 'el', 'es', 'et', 'hu', 'it', 'ja', 'lv', 'lt', 'nl', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sv', 'zh'];
  private allowedLaravelGoogleLanguages: string[] = ['af', 'sq', 'am', 'ar', 'hy', 'az', 'eu', 'be', 'bn', 'bs', 'ca', 'hr', 'eo', 'fy', 'gl', 'ka', 'gu', 'haw', 'hi', 'is', 'ig', 'id', 'ga', 'kn', 'kk', 'km', 'rw', 'ko', 'ky', 'lo', 'lb', 'mk', 'mg', 'ms', 'ml', 'mt', 'mr', 'mn', 'my', 'ne', 'or', 'ps', 'fa', 'pa', 'gd', 'sr', 'sn', 'sd', 'si', 'so', 'sw', 'tg', 'ta', 'tt', 'te', 'th', 'tr', 'tk', 'uk', 'ur', 'ug', 'uz', 'vi', 'cy', 'yi', 'yo', 'zu', 'he']
  private localeId: string = 'en-US';
  private localeShortId: string = 'en';
  private timeZone: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public setLocale(locale: string): void {
    console.log("Setting locale to:", locale);

    this.document.documentElement.lang = locale;
    this.localeId = locale;
    this.localeShortId = locale.substring(0, locale.indexOf('-'));
    this.loadCultureFiles(this.localeShortId);
  }

  public getLocale(): string {
    return this.localeId;
  }

  public getBrowserLocale(): string {
    const supportedLocales = this.allowedLaravelDeeplLanguages.concat(this.allowedLaravelGoogleLanguages);
    const browserLocale = navigator.language;
    return supportedLocales.includes(browserLocale.substring(0, browserLocale.indexOf('-'))) ? browserLocale : 'en-US';
  }

  public getBrowserLocales(): readonly string[] {
    return navigator.languages || ['en-US'];
  }

  public setTimeZone(timeZone: string): void {
    this.timeZone = timeZone;
  }

  public getTimeZone(): string {
    return this.timeZone;
  }

  public getBrowserTimeZone(): string {
    if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return '';
  }

  private loadCultureFiles(name: any): void {
    console.log("Loading culture files for:", name);

    const mainPath = `./assets/cldr-data/main/${name}/all.json`;
    const weekDataPath = `./assets/cldr-data/supplemental/weekData.json`;
    const currencyDataPath = `./assets/cldr-data/supplemental/currencyData.json`;
    const numberingSystemsPath = `./assets/cldr-data/supplemental/numberingSystems.json`;
    const timeZonePath = `./assets/cldr-data/main/${name}/timeZoneNames.json`;
    const numbersPath = `./assets/cldr-data/main/${name}/numbers.json`;
    const currenciesPath = `./assets/cldr-data/main/${name}/currencies.json`;
    const caIslamicPath = `./assets/cldr-data/main/${name}/ca-islamic.json`;
    const caGregorianPath = `./assets/cldr-data/main/${name}/ca-gregorian.json`;

    const loadJsonFile = (path: string) => {
      return new Promise<any>((resolve, reject) => {
        const ajax = new Ajax(path, 'GET', false);
        ajax.onSuccess = (value: any) => resolve(JSON.parse(value));
        ajax.onFailure = (error: any) => reject(error);
        ajax.send();
      });
    };

    Promise.all([
      loadJsonFile(mainPath),
      loadJsonFile(weekDataPath),
      loadJsonFile(currencyDataPath),
      loadJsonFile(numberingSystemsPath),
      loadJsonFile(timeZonePath),
      loadJsonFile(numbersPath),
      loadJsonFile(currenciesPath),
      loadJsonFile(caIslamicPath),
      loadJsonFile(caGregorianPath)
    ]).then(([mainData, weekData, currencyData, numberingSystemsData, timeZoneData, numbersData, currenciesData, caIslamicData, caGregorianData]) => {
      // Load each JSON data into the CLDR
      loadCldr(mainData, weekData, currencyData, numberingSystemsData, timeZoneData, numbersData, currenciesData, caIslamicData, caGregorianData);
      // Set the culture
      setCulture(name);
    }).catch(error => {
      console.error("Error loading culture files:", error);
    });
  }

  public factoryProvider(): string {
    let browserLocale = this.getBrowserLocale();
    this.setLocale(browserLocale);
    this.setTimeZone(this.getBrowserTimeZone());
    return browserLocale;
  }
}
