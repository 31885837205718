<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('HOMEICONSEDIT' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <app-tooltip [icon]="'help'" [iconClass]="'fs-5 p-1'" [text]="('HOMEICONSEDITTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="d-flex flex-column h-100 p-3">
      <!-- Scrollable content-->
      <div class="homeReorderListBox custom-scrollbar overflow-y-auto">
        <ng-container *ngFor="let icon of iconsAllowed; let i = index;">
          <div class="card mb-2">
            <div class="d-flex align-items-stretch">
              <div class="d-flex align-items-center justify-content-center border-end">
                <div class="icon-size">
                  <app-icon-badge *ngIf="icon === 'intranet'" [style]="'homeIcons'" [icon]="'calendar_view_day'" [color]="'darkblue'" [title]="'NEWS' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'mapp'" [style]="'homeIcons'" [icon]="'psychology'" [color]="'orange'" [title]="'KNOWLEDGEBASE' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'chat'" [style]="'homeIcons'" [icon]="'chat'" [color]="'aqua'" [title]="'CHAT' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'channel'" [style]="'homeIcons'" [icon]="'forum'" [color]="'yellow'" [title]="'CHANNEL' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'meeting'" [style]="'homeIcons'" [icon]="'videocam'" [color]="'blue'" [title]="'MEETING' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'calendar'" [style]="'homeIcons'" [icon]="'calendar_month'" [color]="'asphalt'" [title]="'CALENDAR' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'survey'" [style]="'homeIcons'" [icon]="'cycle'" [color]="'lightpurple'" [title]="'FEEDBACK' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'workflow'" [style]="'homeIcons'" [icon]="'rebase'" [color]="'pink'" [title]="'WORKFLOW' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'document'" [style]="'homeIcons'" [icon]="'folder'" [color]="'brightgreen'" [title]="'DOCUMENTMANAGER' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'tv'" [style]="'homeIcons'" [icon]="'tv'" [color]="'cyan'" [title]="'TV' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'employee'" [style]="'homeIcons'" [icon]="'import_contacts'" [color]="'darkorange'" [title]="'CONTACTS' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'profile'" [style]="'homeIcons'" [icon]="'perm_contact_calendar'" [color]="'purple'" [title]="'PROFILE' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'settings_users'" [style]="'homeIcons'" [icon]="'person'" [color]="'darkgreen'" [title]="'USER' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'settings_groups'" [style]="'homeIcons'" [icon]="'group'" [color]="'skyblue'" [title]="'GROUPS' | translate"></app-icon-badge>
                  <app-icon-badge *ngIf="icon === 'general'" [style]="'homeIcons'" [icon]="'settings'" [color]="'red'" [title]="'GENERAL' | translate"></app-icon-badge>
                </div>
              </div>

              <div class="flex-grow-1 d-flex ps-2 align-items-center">
                <app-headline *ngIf="icon === 'intranet'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'NEWS' | translate"></app-headline>
                <app-headline *ngIf="icon === 'mapp'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'KNOWLEDGEBASE' | translate"></app-headline>
                <app-headline *ngIf="icon === 'chat'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'CHAT' | translate"></app-headline>
                <app-headline *ngIf="icon === 'channel'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'CHANNEL' | translate"></app-headline>
                <app-headline *ngIf="icon === 'meeting'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'MEETING' | translate"></app-headline>
                <app-headline *ngIf="icon === 'calendar'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'CALENDAR' | translate"></app-headline>
                <app-headline *ngIf="icon === 'survey'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'FEEDBACK' | translate"></app-headline>
                <app-headline *ngIf="icon === 'workflow'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'WORKFLOW' | translate"></app-headline>
                <app-headline *ngIf="icon === 'document'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'DOCUMENTMANAGER' | translate"></app-headline>
                <app-headline *ngIf="icon === 'tv'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'TV' | translate"></app-headline>
                <app-headline *ngIf="icon === 'employee'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'CONTACTS' | translate"></app-headline>
                <app-headline *ngIf="icon === 'profile'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'PROFILE' | translate"></app-headline>
                <app-headline *ngIf="icon === 'settings_users'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'USER' | translate"></app-headline>
                <app-headline *ngIf="icon === 'settings_groups'" [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'GROUPS' | translate"></app-headline>
                <app-headline *ngIf="icon === 'general'"  [type]="'h6'" [class]="'mb-0 text-muted fs-normal'" [title]="'GENERAL' | translate"></app-headline>
              </div>

              <div class="d-flex align-items-center justify-content-center border-start px-2">
                <app-form-button *ngIf="i !== 0" [style]="'btn'" [cssClass]="'btn-link p-0 text-muted'" [icon]="'keyboard_arrow_up'" [iconClass]="'fs-5'" (click)="moveIcon('up', i, icon)"></app-form-button>
                <app-form-button *ngIf="i !== iconsAllowed.length - 1" [style]="'btn'" [cssClass]="'btn-link p-0 text-muted'" [icon]="'keyboard_arrow_down'" [iconClass]="'fs-5'" (click)="moveIcon('down', i, icon)"></app-form-button>
              </div>

              <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                <app-form-checkbox [fCN]="icon" [fG]="iconsForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="d-flex align-items-center justify-content-center p-2">
      <app-form-button [text]="'SAVE' | translate" (click)="save()" [cssClass]="'btn btn-primary mx-auto w-50'"></app-form-button>
    </div>
  </ng-template>
</ejs-dialog>
