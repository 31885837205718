import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DialogService} from "../../../shared/services/dialog.service";
import {TYPE} from "../../../shared/types/types";
import {DialogModule, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {NgForOf, NgIf, NgSwitch, NgSwitchCase, SlicePipe} from "@angular/common";
import {StepHeaderComponent} from "../../../shared/elements/step-header/step-header.component";
import {LanguageService} from "../../../shared/services/language.service";
import {StepContentComponent} from "../../../shared/elements/step-content/step-content.component";
import {NumericTextBoxModule, TextAreaModule, TextBoxModule} from "@syncfusion/ej2-angular-inputs";
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {ContentComponent} from "../../../shared/elements/content/content.component";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {FormLabelComponent} from "../../../shared/elements/form-label/form-label.component";
import {FormTextareaComponent} from "../../../shared/elements/form-textarea/form-textarea.component";
import {FormInputComponent} from "../../../shared/elements/form-input/form-input.component";
import {arrayValidator} from "../../../shared/validators/array.directive";
import {FormDropdownComponent} from "../../../shared/elements/form-dropdown/form-dropdown.component";
import {FormDateComponent} from "../../../shared/elements/form-date/form-date.component";
import {DatePickerModule, DateTimePickerModule, TimePickerModule} from "@syncfusion/ej2-angular-calendars";
import {FormTimeComponent} from "../../../shared/elements/form-time/form-time.component";
import {RadioButtonModule} from "@syncfusion/ej2-angular-buttons";
import {FormRadioComponent} from "../../../shared/elements/form-radio/form-radio.component";
import {FormNumericComponent} from "../../../shared/elements/form-numeric/form-numeric.component";
import {FormDateTimeComponent} from "../../../shared/elements/form-date-time/form-date-time.component";
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {CalendarService} from "../../../shared/services/calendar.service";
import {FormUserGroupMailComponent} from "../../../shared/elements/form-user-group-mail/form-user-group-mail.component";
import {FormCheckboxComponent} from "../../../shared/elements/form-checkbox/form-checkbox.component";

@Component({
  selector: 'app-dialog-calendar-event',
  standalone: true,
  imports: [
    DialogModule,
    NgForOf,
    StepHeaderComponent,
    StepContentComponent,
    TooltipModule,
    NgSwitch,
    TextBoxModule,
    DropDownListModule,
    ContentComponent,
    HeadlineComponent,
    TranslatePipe,
    FormButtonComponent,
    TooltipComponent,
    NgSwitchCase,
    ReactiveFormsModule,
    FormLabelComponent,
    FormTextareaComponent,
    FormInputComponent,
    TextAreaModule,
    FormDropdownComponent,
    FormDateComponent,
    DatePickerModule,
    TimePickerModule,
    FormTimeComponent,
    DateTimePickerModule,
    NgIf,
    NumericTextBoxModule,
    RadioButtonModule,
    FormRadioComponent,
    FormNumericComponent,
    FormDateTimeComponent,
    IconBadgeComponent,
    SlicePipe,
    FormUserGroupMailComponent,
    FormCheckboxComponent
  ],
  templateUrl: './dialog-calendar-event.component.html',
  styleUrl: './dialog-calendar-event.component.scss'
})
export class DialogCalendarEventComponent implements OnInit, OnChanges {

  @Input() calendarEvent: TYPE.CalendarEvent = {} as TYPE.CalendarEvent;
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public steps: string[] = [this.languageService.getTranslationByKey('CALEVENTDETAILS'), this.languageService.getTranslationByKey('CALEVENTDATELOC'), this.languageService.getTranslationByKey('CALEVENTGUESTS')];
  public activeStep: number = 0;
  public priorityOptions: any[] = [{id: 'high', name: this.languageService.getTranslationByKey('HIGH')}, {id: 'medium', name: this.languageService.getTranslationByKey('MEDIUM')}, {id: 'low', name: this.languageService.getTranslationByKey('LOW')}];
  public typeOptions: any[] = [{id: 'days', name: this.languageService.getTranslationByKey('DAYS')}, {id: 'weeks', name: this.languageService.getTranslationByKey('WEEKS')}, {id: 'months', name: this.languageService.getTranslationByKey('MONTHS')}];
  public endOptions: any[] = [{id: 'after', name: this.languageService.getTranslationByKey('ENDAFTER')}, {id: 'on', name: this.languageService.getTranslationByKey('ON')}, {id: 'never', name: this.languageService.getTranslationByKey('NEVER')}];
  public typeRepeatOptions: any[] = [
    {id: 'repeats', name: this.languageService.getTranslationByKey('REPEATS')},
    {id: 'days', name: this.languageService.getTranslationByKey('DAYS')},
    {id: 'weeks', name: this.languageService.getTranslationByKey('WEEKS')},
    {id: 'months', name: this.languageService.getTranslationByKey('MONTHS')}
  ];
  public calendarEventForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(35)]],
    priority: ['medium', [Validators.required, arrayValidator(this.priorityOptions.map(item => item.id))]],
    description: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
    onDateTimeStart: ['', [Validators.required]],
    is_wholeday: [''],
    timeStart: ['', [Validators.required]],
    timeEnd: ['', [Validators.required]],
    frequency: ['once', [Validators.required]],
    everyDays: [10],
    maxParticipants: [],
    everyType: ['days', [arrayValidator(this.typeOptions.map(item => item.id))]],
    everyEnd: ['after', [arrayValidator(this.endOptions.map(item => item.id))]],
    everyRepeatDays: [10],
    everyRepeatType: ['repeats', [arrayValidator(this.typeRepeatOptions.map(item => item.id))]],
    onDateTimeEnd: [''],
    type: ['physical', [Validators.required, arrayValidator(this.priorityOptions.map(item => item.id))]],
    physicalText: [''],
    virtualText: ['']
  });
  public events: Date[] = [];
  public eventsShowAll = false;
  public participantsUserIDs: number[] = [108];
  public participantsGroupIDs: number[] = [8,6];
  public participantsMails: string[] = [];
  public pushUserIDs: number[] = [];
  public pushGroupIDs: number[] = [];

  constructor(
    public dialogService: DialogService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private calendarService: CalendarService
  ) {
  }

  public ngOnInit(): void {
    this.calendarEventForm.valueChanges.subscribe((value: any) => {
      this.events = this.calendarService.calculateEvents();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {

  }

  public save(): void {
    console.log('Save');
    this.visibleChange.emit(false);
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

}
