<app-bar-top></app-bar-top>

<div id="app-home" class="main-container d-flex p-2 p-md-3" role="main" [attr.aria-label]="('CUSTOMERPAGE' | translate)">
  <div class="inner-container w-100 h-100 d-flex flex-column flex-lg-row overflow-hidden custom-scrollbar">
    <!-- Right Column for Logo -->
    <div class="col-xl-8 col-lg-7 col-md-12 d-flex flex-column justify-content-center align-items-center order-1 order-lg-3 custom-scrollbar overflow-xs-visible overflow-sm-visible overflow-md-auto p-sm-2 p-md-3">
      <div class="scroll-wrapper w-100">
        <div class="dsb-container w-100 mx-auto">
          <!-- Headline that appears when columns are wrapping -->
          <div class="d-flex flex-column justify-content-center align-items-center d-lg-none mb-3">
            <!-- Centered Company Logo -->
            <div class="home-company-logo mb-4">
              <img [ngSrc]="customerService.getCustomer()!.images.customer_icon" [width]="150" [height]="53" [alt]="('CUSTOMERIMAGE' | translate)" class="img-fluid" priority />
            </div>

            <!-- Row with Two Equal Columns -->
            <div class="d-flex align-items-center">
              <app-avatar [image]="userService.getUser()!.avatar" [name]="userService.getUser()!.first_name + ' ' + userService.getUser()!.last_name" [type]="'xxl'"></app-avatar>
              <div class="flex-grow-1 ms-3">
                <app-headline [type]="'h4'" [title]="('HALLO' | translate) + ', ' + userService.getUser()!.first_name" [class]="'mb-1'"></app-headline>
                <p class="m-0">{{ currentDate | date : 'fullDate' }}</p>
              </div>
            </div>
          </div>
          <!-- Top Section: Settings Icon -->
          <div class="d-flex justify-content-end mb-3">
            <app-icon-badge (click)="homeIconsDialog = true;" [style]="'notification'" [icon]="'tune'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
          </div>

          <!-- Tab Content for Icons -->
          <div class="tab-content">
            <div class="tab-pane fade {{ page === 1 ? 'show active' : ''}}" role="tabpanel">
              <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                <app-icon-badge *ngIf="iconsPageOne.indexOf('intranet') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'calendar_view_day'" [color]="'darkblue'" [badge]="iconsBadges.intranet" [title]="'NEWS' | translate" (click)="routerService.navigateTo('/news')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('mapp') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'psychology'" [color]="'orange'" [badge]="iconsBadges.mapp" [title]="'KNOWLEDGEBASE' | translate" (click)="routerService.navigateTo('/mapp')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('chat') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'chat'" [color]="'aqua'" [badge]="iconsBadges.chat" [title]="'CHAT' | translate" (click)="routerService.navigateTo('/chat')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('channel') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'forum'" [color]="'yellow'" [badge]="iconsBadges.channel" [title]="'COMMUNITY' | translate" (click)="routerService.navigateTo('/forum')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('meeting') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'videocam'" [color]="'blue'" [badge]="iconsBadges.meeting" [title]="'MEETING' | translate" (click)="routerService.navigateTo('/meetings')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('calendar') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'calendar_month'" [color]="'asphalt'" [badge]="iconsBadges.calendar" [title]="'CALENDAR' | translate" (click)="routerService.navigateTo('/calendar')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('survey') !== -1" [style]="'home'" [orientation]="'topRight'" [icon]="'cycle'" [color]="'lightpurple'" [badge]="iconsBadges.survey" [title]="'FEEDBACK' | translate" (click)="routerService.navigateTo('/feedback')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('workflow') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'rebase'" [color]="'pink'" [badge]="iconsBadges.workflow" [title]="'WORKFLOW' | translate" (click)="routerService.navigateTo('/workflow')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('document') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'folder'" [color]="'brightgreen'" [title]="'DOCUMENTMANAGER' | translate" (click)="routerService.navigateTo('/documents')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('tv') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'tv'" [color]="'cyan'" [title]="'TV' | translate" (click)="routerService.navigateTo('/tv')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('employee') !== -1" [style]="'home'" [orientation]="'topRight'" [icon]="'import_contacts'" [color]="'darkorange'" [title]="'CONTACTS' | translate" (click)="routerService.navigateTo('/employees')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('profile') !== -1" [style]="'home'" [orientation]="'topRight'" [icon]="'perm_contact_calendar'" [color]="'purple'" [title]="'PROFILE' | translate" (click)="routerService.navigateTo('/profile')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('settings_users') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'person'" [color]="'darkgreen'" [title]="'USER' | translate" (click)="routerService.navigateTo('/admin/users')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('settings_groups') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'group'" [color]="'skyblue'" [title]="'GROUPS' | translate" (click)="routerService.navigateTo('/admin/groups')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageOne.indexOf('general') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'settings'" [color]="'red'" [title]="'SETTINGS' | translate" (click)="routerService.navigateTo('/general')"></app-icon-badge>
              </div>
            </div>
            <div class="tab-pane fade {{ page === 2 ? 'show active' : ''}}" role="tabpanel">
              <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('intranet') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'calendar_view_day'" [color]="'darkblue'" [badge]="iconsBadges.intranet" [title]="'NEWS' | translate" (click)="routerService.navigateTo('/news')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('mapp') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'psychology'" [color]="'orange'" [badge]="iconsBadges.mapp" [title]="'KNOWLEDGEBASE' | translate" (click)="routerService.navigateTo('/mapp')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('chat') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'chat'" [color]="'aqua'" [badge]="iconsBadges.chat" [title]="'CHAT' | translate" (click)="routerService.navigateTo('/chat')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('channel') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'forum'" [color]="'yellow'" [badge]="iconsBadges.channel" [title]="'COMMUNITY' | translate" (click)="routerService.navigateTo('/forum')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('meeting') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'videocam'" [color]="'blue'" [badge]="iconsBadges.meeting" [title]="'MEETING' | translate" (click)="routerService.navigateTo('/meetings')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('calendar') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'calendar_month'" [color]="'asphalt'" [badge]="iconsBadges.calendar" [title]="'CALENDAR' | translate" (click)="routerService.navigateTo('/calendar')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('survey') !== -1" [style]="'home'" [orientation]="'topRight'" [icon]="'cycle'" [color]="'lightpurple'" [badge]="iconsBadges.survey" [title]="'FEEDBACK' | translate" (click)="routerService.navigateTo('/feedback')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('workflow') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'rebase'" [color]="'pink'" [badge]="iconsBadges.workflow" [title]="'WORKFLOW' | translate" (click)="routerService.navigateTo('/workflow')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('document') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'folder'" [color]="'brightgreen'" [title]="'DOCUMENTMANAGER' | translate" (click)="routerService.navigateTo('/documents')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('tv') !== -1" [style]="'home'" [orientation]="'bottomRight'" [icon]="'tv'" [color]="'cyan'" [title]="'TV' | translate" (click)="routerService.navigateTo('/tv')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('employee') !== -1" [style]="'home'" [orientation]="'topRight'" [icon]="'import_contacts'" [color]="'darkorange'" [title]="'CONTACTS' | translate" (click)="routerService.navigateTo('/employees')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('profile') !== -1" [style]="'home'" [orientation]="'topRight'" [icon]="'perm_contact_calendar'" [color]="'purple'" [title]="'PROFILE' | translate" (click)="routerService.navigateTo('/profile')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('settings_users') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'person'" [color]="'darkgreen'" [title]="'USER' | translate" (click)="routerService.navigateTo('/admin/users')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('settings_groups') !== -1" [style]="'home'" [orientation]="'topLeft'" [icon]="'group'" [color]="'skyblue'" [title]="'GROUPS' | translate" (click)="routerService.navigateTo('/admin/groups')"></app-icon-badge>
                <app-icon-badge *ngIf="iconsPageTwo.indexOf('general') !== -1" [style]="'home'" [orientation]="'bottomLeft'" [icon]="'settings'" [color]="'red'" [title]="'SETTINGS' | translate" (click)="routerService.navigateTo('/general')"></app-icon-badge>
              </div>
            </div>
          </div>

          <!-- Tab Navigation for Pagination -->
          <div class="d-flex justify-content-center mt-3" *ngIf="iconsPageTwo.length">
            <nav [attr.aria-label]="'ICONPAGINATIONARIALABEL' | translate">
              <ul class="pagination pagination-sm">
                  <li class="page-item" aria-current="page">
                    <app-link (click)="page = 1;" [title]="'1'" [style]="'pager'" [active]="page === 1">1</app-link>
                  </li>
                  <li class="page-item" aria-current="page">
                    <app-link (click)="page = 2;" [title]="'2'" [style]="'pager'" [active]="page === 2">2</app-link>
                  </li>
              </ul>
            </nav>
          </div>

          <!-- Settings -->
          <div class="d-flex justify-content-end mt-3 mb-2" *ngIf="iconsAllowed.indexOf('general') !== -1">
            <div class="dropdown">
              <button type="button" class="btn btn-link p-0 text-muted" data-bs-toggle="dropdown" aria-expanded="false">
                <app-icon-badge [style]="'notification'" [icon]="'tune'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
              </button>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="linkManagement">
                <li><app-link (click)="homeLinkManagerDialog = true;" [title]="('MANAGELINKS' | translate)" [style]="'dropdown'">{{ ('MANAGELINKS' | translate) }}</app-link></li>
                <li><app-link (click)="homeLinkDialog = true;" [title]="('ADDLINK' | translate)" [style]="'dropdown'">{{ ('ADDLINK' | translate) }}</app-link></li>
              </ul>
            </div>
          </div>

          <!-- Links -->
          <div class="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 justify-content-center g-2 p-2 p-sm-0 p-lg-2">
            <ng-container *ngFor="let link of userService.getUser()!.external_links | slice: (pageLinks-1) * maxLinksPerPage : pageLinks * maxLinksPerPage; index as i;">
              <app-tooltip [text]="link.name" [opensOn]="'Hover'" [position]="'TopCenter'">
                <div class="d-flex">
                  <div class="link-card d-flex align-items-center flex-grow-1 p-1" (click)="openLink(link)">
                    <div class="icon-background">
                      <app-icon-badge [style]="'link'" [icon]="'link'" [screenReaderText]="'SRLINK' | translate"></app-icon-badge>
                    </div>
                    <span class="link-text flex-grow-1 truncate-text-one-line">{{ link.name }}</span>
                  </div>
                </div>
              </app-tooltip>
            </ng-container>

            <ng-container *ngFor="let placeholder of getPageLinksPlaceHolder()">
              <div class="d-flex" style="visibility: hidden;">
                <div class="link-card d-flex align-items-center flex-grow-1 p-1">
                  <div class="icon-background"></div>
                  <span class="link-text flex-grow-1 truncate-text-one-line">&nbsp;</span>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- Pagination For Link-->
          <div class="d-flex justify-content-center my-3" *ngIf="getPageLinksNumbers().length">
            <nav [attr.aria-label]="'LINKPAGINATIONARIALABEL' | translate">
              <ul class="pagination pagination-sm">
                <ng-container *ngFor="let pageLinksNumber of getPageLinksNumbers(); let i = index">
                  <li class="page-item" [class.active]="pageLinks === i + 1" aria-current="page">
                    <app-link (click)="pageLinks = i + 1" [title]="(i + 1).toString()" [style]="'pager'" [active]="pageLinks === i + 1">{{ i + 1 }}</app-link>
                  </li>
                </ng-container>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- Left Column for Form -->
    <div class="col-xl-4 col-lg-5 col-md-12 d-flex flex-column justify-content-center align-items-center order-2 order-lg-1">
      <div class="w-100 h-100">
        <div class="dsb-container d-flex flex-column h-100 w-100 mx-auto justify-content-center">
          <!-- Headline that appears when columns are NOT wrapping -->
          <div class="d-none flex-column justify-content-center align-items-center d-lg-flex mb-3 p-2">
            <!-- Company Logo -->
            <div class="home-company-logo mb-4">
              <img [ngSrc]="customerService.getCustomer()!.images.customer_icon" [width]="150" [height]="53" [alt]="('CUSTOMERIMAGE' | translate)" class="img-fluid" priority />
            </div>

            <!-- Row with Two Equal Columns -->
            <div class="d-flex align-items-center">
              <app-avatar [image]="userService.getUser()!.avatar" [name]="userService.getUser()!.first_name + ' ' + userService.getUser()!.last_name" [type]="'xxl'"></app-avatar>
              <div class="flex-grow-1 ms-3">
                <app-headline [type]="'h5'" [title]="('HALLO' | translate) + ', ' + userService.getUser()!.first_name" [class]="'mb-1'"></app-headline>
                <p class="m-0">{{ currentDate | date : 'fullDate' }}</p>
              </div>
            </div>
          </div>
          <!--[class.flex-grow-0]="!showCalendarWidget"-->
          <div class="custom-scrollbar overflow-y-auto p-2 flex-grow-0">
            <app-calendar-widget *ngIf="showCalendarWidget"></app-calendar-widget>
            <app-notification *ngIf="latestNotification" [notification]="latestNotification"></app-notification>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-bar-footer></app-bar-footer>

<app-dialog-home-icons *ngIf="homeIconsDialog" [(visible)]="homeIconsDialog"></app-dialog-home-icons>
<app-dialog-home-link *ngIf="homeLinkDialog" [(visible)]="homeLinkDialog"></app-dialog-home-link>
<app-dialog-home-link-manager *ngIf="homeLinkManagerDialog" [(visible)]="homeLinkManagerDialog"></app-dialog-home-link-manager>

