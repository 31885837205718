<div class="calendar-event w-border card shadow-sm rounded-3 mb-2">
  <div class="card-body">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center mb-1">
      <h6 class="m-0">Event Calendar</h6>
      <div class="d-flex align-items-center">
        <button class="btn btn-link text-muted p-0 me-2">
          <div class="mts-icon">chevron_left</div>
        </button>
        <button class="btn btn-link text-muted p-0 me-3">
          <div class="mts-icon">chevron_right</div>
        </button>
        <div class="d-flex align-items-center">
          <button class="btn btn-link text-muted p-0 me-3">
            <div class="mts-icon">event</div>
            <span>Apr</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Days of the Week -->
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="text-center day-container">
        <small class="text-muted">Mon</small>
        <div class="fw-bold py-1">23</div>
        <div class="dots-no-bg">
          <span class="dot"></span>
        </div>
      </div>
      <div class="text-center day-container">
        <small class="text-muted">Tue</small>
        <div class="fw-bold py-1">24</div>
        <div class="dots-no-bg">
          <span class="dot bg-waring"></span>
          <span class="dot bg-success"></span>
        </div>
      </div>
      <div class="text-center day-container">
        <small class="text-muted">Wed</small>
        <div class="fw-bold py-1">25</div>
        <div class="dots-no-bg">
          <span class="dot bg-success"></span>
        </div>
      </div>
      <!-- Highlighted Current Day -->
      <div class="text-center current-day day-container">
        <small class="text-white">Thu</small>
        <div class="fw-bold text-white rounded px-2 py-1">26</div>
        <div class="dots-container-inverted">
          <span class="dot bg-danger"></span>
          <span class="dot bg-success"></span>
          <span class="dot bg-warning"></span>
        </div>
      </div>
      <div class="text-center day-container">
        <small class="text-muted">Fri</small>
        <div class="fw-bold py-1">27</div>
        <div class="dots-no-bg">
          <span class="dot bg-warning"></span>
          <span class="dot bg-danger"></span>
        </div>
      </div>
      <div class="text-center day-container">
        <small class="text-muted">Sat</small>
        <div class="fw-bold py-1">28</div>
        <div class="dots-no-bg">
          <span class="dot bg-danger"></span>
          <span class="dot bg-warning"></span>
          <span class="dot bg-success"></span>
        </div>
      </div>
      <div class="text-center day-container">
        <small class="text-muted">Sun</small>
        <div class="fw-bold py-1">29</div>
        <div class="dots-no-bg">
          <span class="dot bg-danger"></span>
          <span class="dot bg-warning"></span>
        </div>
      </div>
    </div>

    <!-- Options Menu for Days of the Week -->
    <div class="d-flex justify-content-end">
      <div class="dropdown">
        <button type="button" class="btn btn-link p-0 text-muted" data-bs-toggle="dropdown" aria-expanded="false">
          <app-icon-badge [style]="'notification'" [icon]="'more_horiz'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
        </button>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-calendar-menu">
          <li><app-link (click)="calendarEventDialog = true;" [title]="('CALADDEVENT' | translate)" [style]="'dropdown'">{{ ('CALADDEVENT' | translate) }}</app-link></li>
          <li><app-link (click)="calendarEventsDialog = true;" [title]="('CALSHOWALLEVENTS' | translate)" [style]="'dropdown'">{{ ('CALSHOWALLEVENTS' | translate) }}</app-link></li>
        </ul>
      </div>

    </div>

    <!-- Events -->
    <app-item-calendar-event [type]="'widget'" [calenderEvent]="calenderEvent" (edit)="calendarEventDialog = true;" (participants)="calendarParticipantsDialog = true;"></app-item-calendar-event>
  </div>
</div>

<app-dialog-calendar-event *ngIf="calendarEventDialog" [(visible)]="calendarEventDialog"></app-dialog-calendar-event>
<app-dialog-calendar-events *ngIf="calendarEventsDialog" [(visible)]="calendarEventsDialog"></app-dialog-calendar-events>
<app-dialog-calendar-participants *ngIf="calendarParticipantsDialog" [(visible)]="calendarParticipantsDialog" [calenderEvent]="calenderEvent"></app-dialog-calendar-participants>
